import React from 'react';
import About_us from "../Images/ABOUST US 2 PNG.png";


const About = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center bg-light">
          <div className="col-sm-12 col-md-8 col-lg-8 text-center">
            <div className="row justify-content-center mt-5 pt-5 pb-3">
              <h3><span className="color">A</span>bout-us</h3>
              <p>We're your go-to hub for skilled professionals. Browse worker profiles for plumbers, carpenters, electricians, and more.</p>
            </div>
          </div>
        </div>
      </div>
    
      <div className="container ">
          <div className="row ">
              <div className="col-sm-12 col-md-6 col-lg-6 pt-5 about-us-image">
                  <img src={About_us} alt="" />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 pt-3 content-center">
                 <div>
                  <h1 id='about'><span className='color'>W</span>hat is <span className='color'>W</span>orkerlinx ?</h1>
                 <p>When you need to find qualified workers to complete your projects and duties, Workerlinx is the platform of choice. Our goal is to facilitate trustworthy professionals' availability for a broad range of services for people and companies alike. Workerlinx is here to make the process easier and give you the knowledge you require, whether you need labour with cleaning, landscaping, house repairs, or any other work. We want to guarantee a flawless experience for both clients and workers with our thoroughly screened and verified workforce, clear pricing, and committed customer service.</p>
                  <p>The doorway to skilled professionals. Find trustworthy workers for any kind of labor, including cleaning and home repairs. Streamline your projects with easy booking, verified labor, and open pricing. Today, reduce your to-do list.”</p>
                 </div>
              </div>
          </div>
      </div>
    </>
  )
}

export default About
