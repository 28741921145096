import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAllCategories } from "../Componants/api/api_base";
import Skeleton from "react-loading-skeleton";


const Work_Categories = () => {
  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCategoriesApi = async () => {
    try {
      const response = await axios.get(getAllCategories);
      setAllCategories(response.data);
      setLoading(false);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategoriesApi();
  }, []);



  return (
    <>
      <div className="container-fluid">
        <div className="row bg-light justify-content-center">
          <div className="col-sm-12 col-md-8 col-lg-8 text-center">
            <div className="row justify-content-center mt-5 pt-5 pb-3">
              <h3><span className="color">C</span>ategories</h3>
              <p> Whether you're in search of a plumber, electrician or more our platform offers a wide selection of skilled workers ready to assist you.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3 pb-5">
        <div className="container">
          <div className="row">
            {loading ? (
              Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className="col-4 col-md-3 text-center">
                  <div className="category_icons">
                    <div className="border my-2 p-2 rounded">
                      <Skeleton height={130} />
                      <h6><Skeleton width={80} /></h6>
                      <h6><Skeleton width={80} /></h6>
                    </div>
                  </div>
                </div>
              ))
            ) : (allCategories.map((category, index) => (
              <div key={index} className="col-4 col-md-3 text-center">
                <div className="category_icons">
                  <div className="border my-2 p-2 rounded">
                    <img src={category.categoryImg} alt="Icon" />
                    <h6>{category.categoryName}</h6>
                    <h6>{category.categoryNameHindi}</h6>
                  </div>
                </div>
              </div>
            )))}
          </div>
        </div>
      </div>
    </>

  )
}

export default Work_Categories
