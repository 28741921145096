import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAllWorker } from "../Componants/api/api_base";
import Skeleton from "react-loading-skeleton";
import Profile from "../Images/user.avif";

const WorkersApi = ({ containerDesign, searchResult }) => {
    const [workers, setWorkers] = useState([]);
    const [loading, setLoading] = useState(true);

    const getWorkerApi = async () => {
        try {
            const response = await axios.get(`${getAllWorker}?searchQuary=${searchResult}`);
            setWorkers(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getWorkerApi();
    }, [searchResult]);

    return (
        <>
            {loading ? (
                Array.from({ length: 8 }).map((_, index) => (
                    <div key={index} className={`${containerDesign}`}>
                        <div className={`row d-flex justify-content-between border rounded mt-3`}>
                            <div className="col-sm-12 col-md-8 col-lg-8 d-flex">
                                <div className="content-center">
                                    <Skeleton circle={true} height={50} width={50} className="m-2" />
                                </div>
                                <div>
                                    <div><Skeleton width={120} /></div>
                                    <div className="category_Name">
                                        <Skeleton width={180} />
                                    </div>
                                    <div className="hindi_category">
                                        <Skeleton width={180} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 p-4 pt-0 pb-0 d-flex justify-content-end content-center">
                                <div className="whatsapp_phone_icon">
                                    <div className="pt-0 pb-0 category_icon">
                                        <Skeleton width={30} height={30} />
                                    </div>
                                    <div className="pt-0 pb-0 category_icon">
                                        <Skeleton width={30} height={30} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                workers.map((worker, index) => (
                    <div key={index} className={`${containerDesign}`}>
                        {<div className={`row d-flex justify-content-between border rounded mt-3`}>
                            <div className="col-sm-12 col-md-8 col-lg-8 d-flex">
                                <div className="content-center">
                                    {worker.profileImg ? (
                                        <img src={worker.profileImg} width={"50"} height={"50px"} className="rounded-circle img-fit m-2" alt="" />
                                    ) : (
                                        <img src={Profile} width={"50"} height={"50px"} className="rounded-circle img-fit m-2" alt="Default Image" />
                                    )}
                                </div>
                                <div className="">
                                    <div><b>{worker.username}</b> <span className="role-name-font">({worker.role.roleName})</span></div>

                                    <div className="category_Name">
                                        <b>Occupation</b>: {worker.category.length > 0 && worker.category.map((cat, catIndex) => (
                                            <span key={catIndex}>
                                                {cat.categoryName}{catIndex < worker.category.length - 1 && ", "}
                                            </span>
                                        ))}
                                    </div>
                                    <div className="hindi_category">
                                        <b>व्‍यवसाय</b>: {worker.category.length > 0 && worker.category.map((cat, catIndex) => (
                                            <span key={catIndex}>
                                                {cat.categoryNameHindi}{catIndex < worker.category.length - 1 && ", "}
                                            </span>
                                        ))}
                                    </div>

                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 p-4 pt-0 pb-0 d-flex justify-content-end content-center">
                                <div className="">
                                    <div className="whatsapp_phone_icon">
                                        <div className="pt-0 pb-0 category_icon">
                                            <a href={`https://wa.me/91${worker.phone}`} target="blank">
                                                <i className="bi bi-whatsapp text-success cursor fs-3"></i>
                                            </a>
                                        </div>
                                        <div className="pt-0 pb-0 category_icon">
                                            <a href={`tel:+91${worker.phone}`}>
                                                <i className="bi bi-telephone-fill color cursor fs-3"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                ))
            )}
        </>
    );
};

export default WorkersApi;