import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { getAllCategory } from "../Componants/api/api_base";
import { getGetAllOffers } from "../Componants/api/api_base";
import why_choose_us from "../Images/WHY CHOOSE WORKERLINX PNG.png";
import why_We_Started from "../Images/WHAT IS WORKERLINX PNG.png";
import more_category from "../Images/more-category.png";
import login_first_img from "../Images/1-login.png";
import login_second_img from "../Images/2-login.png";
import login_four_img from "../Images/4-login.png";
import login_fifth_img from "../Images/5-login.png";
import "./Home.css";
import Review from "../Review/Review.jsx";


const Home = () => {

  const [categories, setCategories] = useState([]);
  const [banner, setBanner] = useState([]);

  const getCategoryApi = async () => {
    try {
      const response = await axios.get(getAllCategory);
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategoryApi();
  }, []);



  const getOfferApi = async () => {
    try {
      const response = await axios.get(getGetAllOffers);
      setBanner(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOfferApi();
  }, []);


  return (
    <div>
      <div className="container-fluid pt-5 mt-4">
        <div className="row justify-content-around">
          <div className="col-12 col-md-12 text-center p-0 m-0">
            <div className="banner">
              <div id="carouselExample" className="carousel slide">
                <div className="carousel-inner">
                  {banner.map((banners, index) => (
                    <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                      <img
                        src={banners.offerImg}
                        className="d-block w-100 rounded-2 border-banner img-fit" alt="...z "
                      />
                    </div>
                  ))}
                </div>

                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row show_after_600 m-0 mt-4">
          <div className="mb-3 text-center">
            <h3 className="">
              <span className="color">C</span>ategories
            </h3>
          </div>
          <div className="container">
            <div className="row justify-content-around">
              {categories.slice(0, 5).map((category, index) => (
                <div key={index} className="col-4 col-md-2 text-center">
                  <div className="category_icons">
                    <div className="p-2 rounded">
                      <Link to="./Workers"><img src={category.categoryImg} alt="Icon" /></Link>
                      <h6>{category.categoryName}</h6>
                      <h6>{category.categoryNameHindi}</h6>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-4 col-md-2 text-center d-flex align-items-center">
                <Link to="/workCategories" className="text-decoration-none">
                  <div className="p-1 category_icons">
                    <img className="bg-primary" src={more_category} alt="" />
                    <h6 className="text-dark">More Categories</h6>
                    <h6 className="text-dark">अधिक श्रेणियां</h6>
                  </div></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center my-4">
          <div className="col-sm-12 col-md-4 col-lg-4 ">
            <div className="why_choose_us">
              <img src={why_We_Started} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-8 ">
            <div>
              <h4 className="mb-3">What is Workerlinx ??</h4>
              <p>
                Welcome to Workerlinx, your one-stop shop for finding qualified workers to complete your assignments and projects. Workerlinx is here to help, regardless of the type of professional you need: plumber, electrician, carpenter, or any other. We have a wide variety of employees, so you can find the correct skill for every task and always get excellent results. Put an end to the headache of finding trustworthy employees and let Workerlinx streamline your hiring procedure. For all of your service needs, download the Workerlinx app now and discover a world of convenience.
              </p>
            </div>
          </div>
        </div>

        <div className="row align-items-center d-flex flex-row-reverse">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="why_choose_us">
              <img src={why_choose_us} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-8">
            <div>
              <h4 className="mb-3">Why Choose Workerlinx ??</h4>
              <p>
                Workerlinx stands apart in the field of sourcing competent individuals for various activities and projects due to several factors. Regardless of the kind of specialist you require—plumber, electrician, carpenter, or another—our platform provides a wide range of workers so you can select the best one for your specific requirements. Our commitment to dependability is what makes Workerlinx distinctive; each candidate is put through a rigorous screening and verification process, so you know they are trustworthy and qualified.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 pt-5">
            <div className=" pb-3">
              <h3 className="text-center">Download Workerlinx <span className="color">App</span></h3>
            </div>
          </div>
          <div className="col-lg-3 col-6 text-center">
            <img src={login_fifth_img} alt="" className="img-fluid" />
            <h6 className="pt-2 pb-2">Step - 1</h6>
          </div>
          <div className="col-lg-3 col-6 text-center">
            <img src={login_first_img} alt="" className="img-fluid" />
            <h6 className="pt-2 pb-2">Step - 2</h6>
          </div>
          <div className="col-lg-3 col-6 text-center">
            <img src={login_second_img} alt="" className="img-fluid" />
            <h6 className="pt-2 pb-2">Step - 3 </h6>
          </div>
          <div className="col-lg-3 col-6 text-center">
            <img src={login_four_img} alt="" className="img-fluid" />
            <h6 className="pt-2 pb-2">Step - 4</h6>
          </div>

        </div>
      </div>
      <div className="container-fluid bg-light mt-5">
        <div className="row">
          <div className="text-center pt-3">
            <h3><span className="color">T</span>estimonials</h3>
          </div>
          <Review />
        </div>
      </div>
    </div>
  );
};

export default Home;
