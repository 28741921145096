import React from "react";
import "./Review.css";
import user from "../Images/user.avif";

import {
  MDBCarousel,
  MDBCarouselItem,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

export default function App() {
  return (
    <MDBContainer className="my-5">
      <MDBCarousel showControls dark>
        <div>
          <MDBCarouselItem className="active text-center">
            <img
              src={user}
              alt="avatar"
              className="rounded-circle shadow-1-strong mb-4 img-border"
              style={{ width: "120px" }}
            />
            <MDBRow className="d-flex justify-content-center">
              <MDBCol lg="8">
                <h5 className="mb-3 text-dark-color">Sachin Gupta</h5>
                {/* <p className="color">Web Developer</p> */}
                <p className="text-muted">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  "User-friendly interface with comprehensive features. Smooth performance and reliable functionality. Simplifies tasks and boosts productivity. A standout app for its effectiveness and ease of use."
                </p>
              </MDBCol>
            </MDBRow>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon far icon="star" size="sm" />
              </li>
            </ul>
          </MDBCarouselItem>

          <MDBCarouselItem className="text-center">
            <img
              src={user}
              alt="avatar"
              className="rounded-circle shadow-1-strong mb-4 img-border"
              style={{ width: "120px" }}
            />
            <MDBRow className="d-flex justify-content-center">
              <MDBCol lg="8">
                <h5 className="mb-3 text-dark-color">Amit Kushwaha</h5>
                {/* <p className="color">Senior Developer</p> */}
                <p className="text-muted ">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  "Impressive functionality coupled with sleek design. Streamlined user experience and excellent performance. A top choice for productivity and convenience. Well worth the download."
                </p>
              </MDBCol>
            </MDBRow>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon far icon="star" size="sm" />
              </li>
            </ul>
          </MDBCarouselItem>

          <MDBCarouselItem className="text-center">
            <img
              src={user}
              alt="avatar"
              className="rounded-circle shadow-1-strong mb-4 img-border"
              style={{ width: "120px" }}
            />
            <MDBRow className="d-flex justify-content-center">
              <MDBCol lg="8">
                <h5 className="mb-3 text-dark-color">Sushil Kumar</h5>
                {/* <p className="color">App Developer</p> */}
                <p className="text-muted">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  "Innovative design and intuitive interface make this app a must-have. Seamless navigation and robust features enhance user experience. Highly recommended for efficiency seekers."
                </p>
              </MDBCol>
            </MDBRow>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon far icon="star" size="sm" />
              </li>
            </ul>
          </MDBCarouselItem>

          <MDBCarouselItem className="text-center">
            <img
              src={user}
              alt="avatar"
              className="rounded-circle shadow-1-strong mb-4 img-border"
              style={{ width: "120px" }}
            />
            <MDBRow className="d-flex justify-content-center">
              <MDBCol lg="8">
                <h5 className="mb-3 text-dark-color">Sourabh Chaurasia</h5>
                {/* <p className="color">App Developer</p> */}
                <p className="text-muted">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  "This application stands out with its creative design and user-friendly interface, making it an essential addition. Its smooth navigation and comprehensive features elevate the user journey, making it ideal for those seeking efficiency. I highly recommend it for anyone looking to enhance their productivity."
                </p>
              </MDBCol>
            </MDBRow>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon far icon="star" size="sm" />
              </li>
            </ul>
          </MDBCarouselItem>

          <MDBCarouselItem className="text-center">
            <img
              src={user}
              alt="avatar"
              className="rounded-circle shadow-1-strong mb-4 img-border"
              style={{ width: "120px" }}
            />
            <MDBRow className="d-flex justify-content-center">
              <MDBCol lg="8">
                <h5 className="mb-3 text-dark-color">Lucky Tiwari</h5>
                {/* <p className="color">App Developer</p> */}
                <p className="text-muted">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  "This app distinguishes itself through its inventive design and intuitive interface, making it a valuable addition. Its seamless navigation and extensive features enhance the user experience, making it perfect for those aiming for effectiveness. I strongly suggest it for anyone wanting to boost their productivity."
                </p>
              </MDBCol>
            </MDBRow>
            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon fas icon="star" size="sm" />
              </li>
              <li>
                <MDBIcon far icon="star" size="sm" />
              </li>
            </ul>
          </MDBCarouselItem>
        </div>
      </MDBCarousel>
    </MDBContainer>
  );
}