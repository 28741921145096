import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAllBlogs } from "../../Componants/api/api_base";
import { Link } from "react-router-dom";

function BlogFooter() {
    const [blogs, setBlogs] = useState([]);

    const getBlogApi = async () => {
        try {
            const response = await axios.get(`${getAllBlogs}?page=&limit=&searchQuary=`);
            setBlogs(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getBlogApi();
    }, []);
    return (
        <div className='container-fluid py-3 bg-light'>
            <div className="row justify-content-center">
                <div className="col-10">
                    <div className="row">
                        {blogs.map((blog, index) => (
                            <div className="col-sm-12 col-md-6 col-lg-3 pt-2 pb-2 bg-blog-title">
                                <Link to={`/single_blog/${blog._id}`} className="text-decoration-none text-black" > <div key={index}>
                                    <div>
                                        <h1 className="fs-6 blog-title"><i className="bi bi-check-circle color "></i> {blog.title}</h1>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogFooter