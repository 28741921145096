import React, { useState, useEffect } from "react";
import { getSingleBlog } from "../Componants/api/api_base";
import { useParams } from "react-router-dom";
import axios from "axios";
const Single_blog = () => {
  const { id } = useParams();
  const [singleblogs, setsingleBlogs] = useState([]);
  console.log(singleblogs);
  const getBlogApi = async () => {
    try {
      const response = await axios.get(`${getSingleBlog}/${id}`);
      setsingleBlogs(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlogApi();
  }, []);
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 py-5 mt-5">
            <div dangerouslySetInnerHTML={{ __html: singleblogs.content }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Single_blog;
