import React from "react";
import "./Contact_us.css";
import { useForm, ValidationError } from "@formspree/react";
import { useNavigate } from "react-router-dom";
import video from "../Images/contact1.gif"

function ContactForm() {
  const history = useNavigate();
  const [state, handleSubmit] = useForm("mdoqgyjq");
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }
  const handle = () => {
    setTimeout(() => {
      history("/");
      window.location.reload(false)
    }, 1000);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center bg-light">
          <div className="col-sm-12 col-md-8 col-lg-8 text-center">
            <div className="row justify-content-center mt-5 pt-5 pb-3">
              <h3><span className="color">C</span>ontact-us</h3>
              <p>We are open to answering any questions or addressing any problems you may have, and we appreciate your comments.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-5">
        <div className="row pt-5">
          <div className="col-lg-6">
          <div className="gif">
              <img src={video} alt="Gif"/>
            </div>
            <div>
              <h5 className="pt-3 pb-3 blue-first-letter"><span className="color">"Your</span> Digital Journey Starts Here- <br />Reach Out And<span className="color"> Connect"</span></h5>
            </div>
            <div>
              <ul className="contact-details">
                <li><b>Customer support email :</b> Workerlinx@gmail.com</li>
                <li><b>Phone no. :</b> 8700282172</li>
                <li className="contact-us-icons"><b>Social Media :</b>
                  <a href="https://www.facebook.com/people/Worker-Linx/pfbid04dsZGuHx4xrSuDTTRYEVeFmkxwTHABeV3QfDCUnWDD14gMqCDo94v3VpceSPqWWul/?sfnsn=wiwspwa&mibextid=RUbZ1f"><i class="bi bi-facebook text-primary"></i></a>
                  <a href="https://www.instagram.com/workerlinx/?utm_source=qr&igsh=MTluMmdmZ21zZGZhbg%3D%3D"><i class="bi bi-instagram text-danger"></i></a>
                  <a href="https://twitter.com/workerlinx?t=wOtq6OzOJI6W3no9Peg3Ug&s=09"><i class="bi bi-twitter-x text-dark"></i></a>
                </li>
              </ul>
            </div>
           
          </div>
          <div className="col-lg-6">
            <div className="form-section">
              <form action="https://formspree.io/f/mnqedwnw" method="post">
                <div className="mb-3">
                  <label htmlFor="exampleInputName" className="form-label">
                    <i className="bi bi-person-fill color"></i> Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter Your Name"
                    className="form-control"
                    id="exampleInputName"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    <i className="bi bi-envelope-fill color"></i> Email address
                  </label>
                  <input
                    name="email"
                    type="email"
                    placeholder="Enter Your Email"
                    className="form-control"
                    id="exampleInputEmail1"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputnumber" className="form-label">
                    <i className="bi bi-telephone-fill color"></i> Number
                  </label>
                  <input
                    name="number"
                    type="number"
                    placeholder="Enter Your Phone Number"
                    className="form-control"
                    id="exampleInputnumber"
                  />
                </div>
                <label htmlFor="exampleInputnumber" className="form-label">
                  <i className="bi bi-pencil-fill color"></i> Description
                </label>
                <div className="form-floating mb-3">

                  <textarea
                    name="Comments"
                    className="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea"
                    defaultValue={""}
                  />
                  <label htmlFor="floatingTextarea"> Reason to Contact</label>
                </div>

                <button type="submit" className="btn-style" onClick={handle}>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
