import React, {useState} from "react";
import "./Blog.css";
import BlogApi from "./BlogApi";
const Blog = () => {

  const [searchBlog, setSearchBlog] = useState("");

  const handleSearch = (e) => {
    setSearchBlog(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  
  return (
    <div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-12 bg-light text-center">
            <div className="row justify-content-center mt-5 pt-5 pb-3">
              <h3><span className="color">W</span>orkerlinx <span className="color">B</span>log</h3>
              <div className="col-lg-6 py-2">
               <form onSubmit={handleSubmit}>
               <div class="input-group mb-3">
                  <input type="text" value={searchBlog} onChange={handleSearch} class="form-control border-0" placeholder="Search Blog" />
                  <div class="input-group-append">
                  <button className="input-group-text form-control border-0 bg-white" type="submit" id="basic-addon2"><i className="bi bi-search"></i></button>                   </div>
                </div>
               </form>
              </div>
            </div> 
          </div>
          <div className="col-md-10 my-3">
            <div className="row">
               <BlogApi searchBlog={searchBlog} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
