import React from "react";
import "./Single_Profile_Page.css";
import JobsApi from "./JobApi";


const Jobs = () => {

  return (
    <div>
      <div className="container-fluid">
        <div className="row bg-light justify-content-center">
          <div className="col-sm-12 col-md-8 col-lg-8 text-center">
            <div className="row justify-content-center mt-5 pt-5 pb-3">
              <h3><span className="color">F</span>ind <span className="color">J</span>obs</h3>
              <p>Discover job opportunities in plumbing, carpentry, and electrical work. Create your profile showcasing skills and experience.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
            <JobsApi containerDesign={"col-sm-12 col-md-6 col-lg-4 pt-3"}/>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
