import React from 'react';
import "./Footer.css";
import play_store_image from "../../Images/play-store.png";
import BlogFooter from './BlogFooter';
import {Link} from "react-router-dom"; 

const Footer = () => {
  return (
    <>
    <BlogFooter/>
        <div className="container-fluid footer-bg text-white">
            <div className="row justify-content-center">
                <div className="col-sm-12 col-md-6 col-lg-4">
                <a className="navbar-brand logo" href="/"><img src={require('../../Images/logo-1.png')} alt="" className="img-fit" height={60} width="170px" /></a>
                    <p className='p-3'>Workerlinx is here to help, Regardless of the type of Professional you need: Plumber, Electrician, Carpenter or any other.</p>
                    <div className='p-3 pt-0'>
                        <img className='pb-2 pt-2' src={play_store_image} alt="" width={200}/><br />
                    </div>
                </div>

                <div className="col-6 col-md-3 col-lg-3">
                    <h5 className='p-2' href="#"> Menues</h5>
                    <div className='p-2'>
                        <Link className="nav-link text-light" aria-current="page" to="/">
                            <p className='mb-2'>Home</p>
                        </Link>
                        <Link className="nav-link text-light" aria-current="page" to="/about">
                            <p className='mb-2'>About us</p>
                        </Link>
                        <Link className="nav-link text-light" aria-current="page" to="/workers">
                            <p className='mb-2'>Workers</p>
                        </Link>
                        <Link className="nav-link text-light" aria-current="page" to="/jobs">
                            <p className='mb-2'>Jobs</p>
                        </Link>
                        {/* <Link className="nav-link text-light" aria-current="page" to="/workCategories">
                            <p className='mb-2'>Categories</p>
                        </Link> */}
                        {/* <Link className="nav-link text-light" aria-current="page" to="/contact">
                            <p className='mb-2'>Contact us</p>
                        </Link> */}
                        <Link className="nav-link text-light" aria-current="page" to="/blog">
                            <p className='mb-2'>Blogs</p>
                        </Link>
                    </div>
                </div>
                
                <div className="col-6 col-md-3 col-lg-3">
                    <h5 className='p-2' href="#">Social Media</h5>
                    <div className='p-2 social_icons'>
                        <a href="https://www.facebook.com/people/Worker-Linx/pfbid04dsZGuHx4xrSuDTTRYEVeFmkxwTHABeV3QfDCUnWDD14gMqCDo94v3VpceSPqWWul/?sfnsn=wiwspwa&mibextid=RUbZ1f" target='blank'><p className='mb-2'><i class="bi bi-facebook"></i> <span className='p-2 pt-0 pb-0'>Facebook</span></p></a>
                        <a href="https://www.instagram.com/workerlinx/?utm_source=qr&igsh=MTluMmdmZ21zZGZhbg%3D%3D" target='blank'><p className='mb-2'><i class="bi bi-instagram"></i> <span className='p-2 pt-0 pb-0'>Instagram</span></p></a>
                        <a href="https://twitter.com/workerlinx?t=wOtq6OzOJI6W3no9Peg3Ug&s=09" target='blank'><p className='mb-2'><i class="bi bi-twitter-x"></i> <span className='p-2 pt-0 pb-0'>X.com</span></p></a>
                        {/* <a href="#" target='blank'><p className='mb-2'><i class="bi bi-youtube"></i> <span className='p-2 pt-0 pb-0'>Youtube</span></p></a> */}
                        {/* <a href="#" target='blank'><p className='mb-2'><i class="bi bi-linkedin"></i> <span className='p-2 pt-0 pb-0'>LinkedIn</span></p></a> */}
                        {/* <a href="#" target='blank'><p className='mb-2'><i class="bi bi-pinterest"></i> <span className='p-2 pt-0 pb-0'>Pinterest</span></p></a> */}
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid footer-2">
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className='powered-by'>
                        <p>Copywrite@2024 by <b><a className='color' href="https://www.wavemantra.com/" target='blank'>Wave Mantra.</a></b> All Rights Reserved. </p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className='term-and-policy'>
                        <Link className="nav-link color" aria-current="page" to="/terms_conditions">
                            <p className='text-dark'>Terms & Conditions</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Footer
