import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Blog.css";
import { getAllBlogs } from "../Componants/api/api_base";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const BlogApi = ({ searchBlog }) => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const getBlogApi = async () => {
    try {
      const response = await axios.get(`${getAllBlogs}?page=&limit=&searchQuary=${searchBlog}`);
      setBlogs(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlogApi();
  }, [searchBlog]);

  return (
    <>
      {loading ? (
        Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className="col-sm-6 col-md-4 col-lg-4 border rounded p-2 mt-3 blog-container">
            <div >
              <div className="blog_image ">
                <Skeleton height={180} />
              </div>
              <div>
                <h1 className="fs-4 text-center pt-2 pb-2"><Skeleton height={80} /></h1>
              </div>
            </div>
          </div>
        ))
      ) : (
        blogs.map((blog, index) => (
          <div className="col-sm-6 col-md-4 col-lg-4 border rounded p-2 mt-3 blog-container">
            <Link to={`/single_blog/${blog._id}`} className="text-decoration-none text-black" >
              <div key={index}>
                <div className="blog_image ">
                  <img src={blog.images} alt="" className="img-fit" />
                </div>
                <div>
                  <h1 className="fs-4 text-center pt-2 pb-2">{blog.title}</h1>
                </div>
              </div>
            </Link>
          </div>
        ))
      )}
    </>
  );
};

export default BlogApi;
