import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';


function Navbar() {
  const [progress, setProgress] = useState(0)
  // const handleClick = () => {
  //   setProgress(progress + 25)
  //   setTimeout(() => {
  //     setProgress(progress + 100)
  //   }, 800); 
  // }
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.offsetHeight;
      const scrollPercentage = (scrollPosition / (bodyHeight - windowHeight)) * 100;
      setProgress(scrollPercentage);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

  const menuItems = [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'About',
      href: 'about',
    },
    {
      name: 'Workers',
      href: 'workers',
    },
    {
      name: 'Jobs',
      href: 'jobs',
    },
    {
      name: 'Categories',
      href: 'workCategories',
    },
    {
      name: 'Blogs',
      href: 'blog',
    },
    {
      name: 'Contact Us',
      href: 'contact',
    },


  ]
  const menuItemsPhone = [
    {
      name: 'Home',
      href: '/',
      icon:"house-fill"
    },
    {
      name: 'Workers',
      href: 'workers',
      icon:"person-circle"
    },
    {
      name: 'Jobs',
      href: 'jobs',
      icon:"substack"
    },
    {
      name: 'Blogs',
      href: 'blog',
      icon:"grid-fill"
    },


  ]
  return (
    <div>
       <LoadingBar
        color='#ff914c'
        height={3}
        shadow={`0px 0px 10px #BFC2FF`}
        progress={progress}
      />
      <nav className="navbar navbar-expand-lg bg-nav fixed-top shadow-sm p-0 m-0">
        <div className="container-fluid">
          <a className="navbar-brand logo" href="/"><img src={require('../../Images/logo-1.png')} alt="" className="img-fit" height={60} width="170px" /></a>
          <button className="top-navbar-icon">
            <div className="">
              <Link className="nav-link color" aria-current="page" to="/contact">
                <i class="bi bi-headset"></i>
              </Link>
            </div>
          </button>
          <div className={`collapse navbar-collapse`} id="navbarNav" >
            <ul className="navbar-nav">
              {menuItems.map((item) => (
                <li className="nav-item">
                  <NavLink className={({ isActive }) => `nav-link ${isActive ? "navbar-pill" : "text-black"} hover:text-pink-500`} aria-current="page" to={item.href} >
                    {item.name.toUpperCase()}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
      {/* mobile menu */}
      <div className="container-fluid fixed-bottom bottom-nav-section">
        <div className="row">
          <div className="bottom-nav col-12 d-flex justify-content-between bg-light py-2">
           
            {menuItemsPhone.map((item) => (
                <span className="nav-item text-center" >
                  <NavLink style={{fontSize:"13px"}} className={({ isActive }) => `nav-link ${isActive ? "navbar-mobile" : "text-dark"}`} aria-current="page" to={item.href} >
                    <i className={`bi bi-${item.icon}`}></i> <br /> {item.name}
                  </NavLink>
                </span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar
