import React, { useState, useEffect } from "react";
import "./Single_Profile_Page.css";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { getAllJobs } from "../api/api_base";

const JobsApi = ({ containerDesign }) => {
    const [workers, setWorkers] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading indicator

    const getWorkerApi = async () => {
        try {
            const response = await axios.get(getAllJobs);
            setWorkers(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    console.log(workers)

    useEffect(() => {
        getWorkerApi();
    }, []);

    return (
        <div>
            <div className="container">
                <div className="row">
                    {loading ? (
                        Array.from({ length: 6 }).map((_, index) => (
                            <div key={index} className={`${containerDesign}`}>
                                <div className="border rounded p-3">
                                    <div className="d-flex card_icon">
                                        <h5><Skeleton width={100} /></h5>
                                    </div>
                                    <div className="d-flex card_icon">
                                        <p><Skeleton width={150} /></p>
                                    </div>
                                    <div><Skeleton width={50} /></div>
                                    <span className="posted_on">
                                        <Skeleton width={100} />
                                    </span>
                                </div>
                            </div>))
                    ) : (
                        workers.length > 0 ? (
                            workers.map((worker, index) => {
                                const readableDate = new Date(worker.createdAt).toLocaleString('en-US', {
                                    month: 'long', // "February"
                                    day: '2-digit', // "24"
                                    year: 'numeric', // "2024"
                                    // hour: '2-digit', // "12"
                                    // minute: '2-digit', // "09"
                                    // second: '2-digit', // "15"
                                    // timeZoneName: 'short', // "UTC"
                                    // hour12: true // AM/PM format
                                });

                                return (
                                    <div key={index} className={`${containerDesign}`}>
                                        <div className="border rounded p-3">
                                            <div className="d-flex card_icon">
                                                <h5>{worker.work.categoryName}</h5>
                                                <a href={`tel:+91${worker.status}`}>
                                                    <i className="bi bi-telephone-fill color cursor"></i>
                                                </a>
                                            </div>
                                            <div className="d-flex card_icon">
                                                <p><b>Name:</b> {worker.user ? worker.user.username : "Unknown"}</p>
                                            </div>
                                            <div className="duration text-white">Duration: {worker.duration} Days</div>
                                            <span className="posted_on">Posted on {readableDate}</span>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="col-12 text-center">
                                <p>No Posts Found!</p>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default JobsApi;
